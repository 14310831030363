import { Box, Button, Grid, TextInput, Title, Text, Anchor, Checkbox } from '@mantine/core'
import React, { useState } from 'react'
import classes from './SignUp.module.css';
import { createStripeSsession, getStripeRenewSession, handleNonNpiReasonResponse, npiResponse } from '../utils/util';
import { useMedplum } from '@medplum/react';
import { Stepper } from './Stepper';
import { toast } from 'react-toastify';
import InvitePopup from '../patients/InvitePopup';
import RoleSelectionPopup from './RoleSelectionPopup';

function SignUp() {
  const medplum = useMedplum();
  const [npiNumber, setNpiNumber] = React.useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [startWithFreeTrial, setStartWithFreeTrial] = useState(true);
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const isRenew = new URLSearchParams(window.location.search).get('isRenew') === 'true' || isLoggedIn;
  const [isInvitePopupOpen, setInvitePopupOpen] = useState(false);
  const [isRoleSelectionPopup, setRoleSelectionPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  interface PractitionerDetails {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    withNPI?: boolean;
    reason?: string;
  }

  const handleNpiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNpiNumber(event.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handleFreeTrialChange = (event: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setStartWithFreeTrial(event.currentTarget.checked);
  };

  const handleNextClick = async () => {
    if (npiNumber === '') {
      toast.error("Please enter a valid NPI number.");
      return;
    }

    if (email === '' || emailError !== '') {
      toast.error("Please enter a valid email address.");
      return;
    }

    const payload = { npi_number: npiNumber, email: email };
    try {
      const response = await npiResponse(medplum, payload);
      const priceId = response?.data?.priceId;
      const role = response?.data?.role;

      if (!priceId) {
        throw new Error("Invalid response data");
      }

      if (window.location.href.includes('isRenew=true')) {
        const renewPayload = { priceId: priceId };
        try {
          const res = await getStripeRenewSession(medplum, renewPayload);
          window.location.href = res.sessionUrl;
        } catch (err) {
          console.error('Error fetching Stripe renew session:', err);
        }
      } else {
        stripeSession(priceId, startWithFreeTrial, role);
        if (response?.data) {
          sessionStorage.setItem('response_data', JSON.stringify(response.data));
        }
      }
    } catch (error: any) {
      if (error?.response) {
        if (error?.response?.status === 404) {
          setRoleSelectionPopup(true);
        } else if (error?.response?.status === 409) {
          toast.error(error.response.data.message);
          setEmail('');
        } else {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("Please try again later.");
      }
    }
  };

  const stripeSession = (stripePriceId: any, startWithFreeTrial: boolean, role: string) => {
    const payload = {
      priceId: stripePriceId,
      isFreeTrial: startWithFreeTrial,
      role: role,
      npiNumber: npiNumber,
      email: email
    }
    createStripeSsession(payload).then((res) => {
      console.log(res)
      window.location.href = res.sessionUrl;
    }).catch((err) => {
      console.log(err);
    });
  }

  const closePopup = () => {
    setInvitePopupOpen(false);
  };

  const handleInviteSubmit = async (medplum: any, details: PractitionerDetails) => {
    try {
      const response: any = await handleNonNpiReasonResponse(medplum, details);

      if (response) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
        setTimeout(() => {
          medplum.signOut();
          window.location.href = '/signin';
        }, 3000);
      } else {
        toast.error(`Failed to send invitation. Please try again later.`, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error: any) {
      console.error('Error:', error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      closePopup();
    }
  };

  const handleRoleSelectionSubmit = async (selectedRole: string) => {
    const payload = {
      role: selectedRole
    }
    sessionStorage.setItem('response_data', JSON.stringify(payload));
    stripeSession('', startWithFreeTrial, selectedRole);
  };

  const openInvitePopup = async () => {
    setInvitePopupOpen(true);
  };

  return (
    <Box >
      <Grid className={classes.signUpPage}>
        <Grid.Col span={12} className={classes.desktopOnly}>
          <div className={classes.pmhLogo}><img src="../../img/logo.png" alt="PMHScribe Logo" width="200" /></div>
        </Grid.Col>
        {!isRenew && (
          <Grid.Col span={12} pt={0} pb={0}>
            <Stepper totalSteps={4} activeStep={activeStep} setActiveStep={setActiveStep} />
          </Grid.Col>
        )}
        <Grid.Col
          span={12}
          className={` ${isRenew ? classes.marginTop10 : classes.logoAlign}`}

        >
          <div className={classes.centerAlign}>
            <img src="../../img/icons/profile_pic.png" alt="PMHScribe" className={classes.profile}></img>
          </div>
        </Grid.Col>
        <Grid.Col span={8} className={classes.para}>
          <Title order={2} className={classes.centerAlign} >To ensure only qualified healthcare providers use the software, <br />
            Please enter your NPI (National Provider Identifier) number to get started.</Title>
        </Grid.Col>
        <Grid.Col span={12} className={classes.npiSect}>
          {!isRenew && (<Checkbox
            label="Start with free trial"
            checked={startWithFreeTrial}
            onChange={handleFreeTrialChange}
            mb={10}
          />)}
          <div>
            <TextInput
              label="NPI number"
              value={npiNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleNpiChange(e);
                }
              }}
              maxLength={10}
              placeholder="Enter your NPI number"
              className={classes.inputWidth}
            />
            <div className={classes.flexContainer}>
              <TextInput
                label="Email Id"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email ID"
                className={classes.inputWidth}
                error={emailError}
              />
              <Button mt={22} ml={20} onClick={handleNextClick} className={classes.nextBtn}>
                Next
              </Button>
            </div>
          </div>
          <Anchor mt={22} href="https://npiregistry.cms.hhs.gov/search" target='_blank' sx={{ textDecoration: 'underline' }}>Find my NPI</Anchor>
          <div><Anchor mt={22} onClick={openInvitePopup} sx={{ textDecoration: 'underline' }}>I don't have NPI</Anchor></div>
        </Grid.Col>
        <Grid.Col span={12} mt={22} className={classes.colSect}>
          {!isRenew && (
            <Anchor href="/sign-in" className={classes.underlineText}>
              I already have an account. Sign in.
            </Anchor>
          )}
          <hr className={classes.borderTop} />
          <div className={classes.privacyText}>
            <div className={classes.flexDiv}>
              <img src="../../img/HIPAA.png" alt="HIPAA Logo" width="80" /></div>
            <Text >Your data is kept secure under HIPAA, HITECH, and Mental Health Privacy laws. You may keep patient health information and records in our system, sync them to your electronic health record, or choose not to enter patient details. By signing up for PMHScribe, you agree to our <Anchor href='https://pmhscribe.com/privacy-policy/' target='_blank'>Privacy Policy</Anchor> and <Anchor href='https://pmhscribe.com/terms-and-conditions/' target='_blank'>Terms of Use</Anchor>.</Text>
          </div>

        </Grid.Col>
      </Grid>
      <InvitePopup
        isOpen={isInvitePopupOpen}
        onClose={closePopup}
        onSubmit={(details) => handleInviteSubmit(medplum, details)}
        header="Non-NPI user details"
        submitButtonLabel="Submit"
        cancelButtonLabel="Cancel"
        isLoading={false}
      />
      <RoleSelectionPopup
        isOpen={isRoleSelectionPopup}
        onClose={() => setRoleSelectionPopup(false)}
        onSubmit={(selectedRole) => handleRoleSelectionSubmit(selectedRole)}
        submitButtonLabel="Submit"
        cancelButtonLabel="Cancel"
      />
    </Box>
  )
}

export default SignUp
