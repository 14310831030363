import React from 'react';
import { Modal, Button, Group, Text, Loader } from '@mantine/core';

interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    content: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    isLoading?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    content,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    isLoading = false,
}) => {
    return (
        <Modal opened={isOpen} onClose={onClose} withCloseButton={false}>
            <Text style={{ fontSize: '20px', lineHeight: '30px', textAlign: 'center' }} fw={500} mt={20} mb={30}>{content}</Text>
            <Group position="right" mt="md">
                <Button className="btn-danger" px={'lg'} style={{ borderRadius: '25px' }} onClick={onClose}>
                    {cancelButtonText}
                </Button>
                <Button className="btn-success" mr={10} px={'lg'} style={{ borderRadius: '25px' }} onClick={onConfirm}>
                    {isLoading ? (
                        <Group spacing="xs">
                            <span>{confirmButtonText}...</span>
                            <Loader size="xs" color="white" />
                        </Group>
                    ) : (
                        confirmButtonText
                    )}
                </Button>
            </Group>
        </Modal>
    );
};

export default ConfirmationModal;