import { Space } from '@mantine/core';
import { MEDPLUM_VERSION } from '@medplum/core';
import { UserConfiguration, UserConfigurationMenuLink } from '@medplum/fhirtypes';
import { AppShell, Loading, Logo, NavbarMenu, useMedplum } from '@medplum/react';
import {
  Icon,
  IconBrandAsana,
  IconBuilding,
  IconForms,
  IconId,
  IconLockAccess,
  IconMicroscope,
  IconPackages,
  IconReceipt,
  IconReportMedical,
  IconStar,
  IconTemplate,
  IconWebhook,
} from '@tabler/icons-react';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css?v=1.0.10';
import SubscriptionExpiredModel from './SubscriptionExpiredModel';
import { checkSubscriptionExpired, getMessengerAuthDetails, getStripeSession } from './utils/util';
import { showNotification } from '@mantine/notifications';
import Intercom from '@intercom/messenger-js-sdk';

interface ExtendedUserConfigurationMenuLink extends UserConfigurationMenuLink {
  subLinks?: UserConfigurationMenuLink[];
}

export function App(): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const [isCheckSubscriptionExpired, setIsCheckSubscriptionExpired] = React.useState<boolean>(false);
  const searchURL = new URLSearchParams(window.location.search);
  const sessionId = searchURL.get('sessionId');
  const isSignUpPage = location.pathname.includes('/sign-up');
  const [subscriptionTitle, setSubscriptionTitle] = useState<string>('');
  const [buttonLabel, setButtonLabel] = useState<string>('Renew');
  const isSignInPage = location.pathname.includes('/signin');
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts?.[1];

  useEffect(() => {
    if (!sessionId && medplum.getActiveLogin()) {
      checkSubscriptionExpired(medplum).then((response: any) => {
        setIsCheckSubscriptionExpired(response.subscription_expired);
        if (response.subscription_expired) {
          if (response.paymentStatus === 'Unpaid') {
            setSubscriptionTitle('Your free PMHScribe trial has expired. Subscribe to our paid plan.');
            setButtonLabel('Subscribe');
          } else {
            setSubscriptionTitle('Your subscription has expired. Please renew now to continue using the service.');
            setButtonLabel('Renew');
          }
        }
        //setSubscriptionPlan(response.plan);
      }).catch((error: any) => {
        console.error('Error:', error);
        if (error?.response?.status === 401) {
          toast.error('Your session has expired. Please sign-in again.');
          setTimeout(async () => {
            await medplum.signOut();
            navigate('/signin');
          }, 1500);
          return;
        }
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (sessionId && !window.location.pathname.includes('/register')) {
      const payload = {
        sessionId: sessionId,
      }
      getStripeSession(payload).then((data) => {
        if (data.payment_status === 'paid') {
          showNotification({ color: 'green', message: 'Your subscription has been successfully renewed.' });
          navigate('/session');
        }
      }).catch((error) => {
        console.log(error)
      });
    }
  }, [sessionId]);

  useEffect(() => {
    const fetchData = async () => {
      const isShow = window.location.pathname !== '/session' ? true : false;
      if (!isShow) {
        const authData = await getMessengerAuthDetails(medplum);
        if (authData) {
          return Intercom({
            app_id: authData.messengerId,
            user_id: authData.id,
            name: authData.name,
            email: authData.email,
            created_at: authData.createdDate,
            user_hash: authData.hash,
          });
        }
      } else {
        if (window.Intercom) {
          window.Intercom('shutdown');
        }
      }
    };
    fetchData();

  }, [window.location.pathname]);

  if (medplum.isLoading()) {
    return <Loading />;
  }

  return (
    <AppShell
      logo={<Logo size={120} />}
      pathname={location.pathname}
      searchParams={searchParams}
      version={MEDPLUM_VERSION}
      menus={userConfigToMenu(config)}
      displayAddBookmark={!!config?.id}
    >
      <Suspense fallback={<Loading />}>
        {!isSignInPage && !isSignUpPage && isCheckSubscriptionExpired ? (
          <SubscriptionExpiredModel isOpen={true} onClose={() => false} title={subscriptionTitle} buttonLabel={buttonLabel} practitionerId={practitionerId} />
        ) : (
          <AppRoutes />
        )}
      </Suspense>
      <ToastContainer />
    </AppShell>
  );
}

function userConfigToMenu(config: UserConfiguration | undefined): NavbarMenu[] {
  const currentUrl = window.location.href.toLowerCase();
  if ((currentUrl.includes("composition") || currentUrl.includes("admin/secrets") || currentUrl.includes("/user-activity") || currentUrl.includes("/cost-revenue")) && config?.menu?.some(menu => menu.title === "User")) {
    window.location.href = window.location.origin;
    return [];
  }

  const result = config?.menu?.map((menu) => ({
    title: menu.title,
    links: (menu.link as ExtendedUserConfigurationMenuLink[])?.map((link) => ({
      label: getDisplayName(link.name as string).displayName,
      href: link.target as string,
      icon: getIcon(link.target as string),
      subLinks: link.subLinks?.map((subLink) => ({
        label: getDisplayName(subLink.name as string).displayName,
        href: subLink.target as string,
        icon: getIcon(subLink.target as string),
      })) || [],
    })) || [],
  })) || [];

  return result;
}

const resourceTypeToIcon: Record<string, Icon> = {
  Patient: IconStar,
  Practitioner: IconId,
  Organization: IconBuilding,
  ServiceRequest: IconReceipt,
  DiagnosticReport: IconReportMedical,
  Questionnaire: IconForms,
  admin: IconBrandAsana,
  AccessPolicy: IconLockAccess,
  Subscription: IconWebhook,
  batch: IconPackages,
  Observation: IconMicroscope,
  Appointment: IconReceipt,
  Composition: IconTemplate,
};

function getIcon(to: string): JSX.Element | undefined {
  try {
    const resourceType = new URL(to, 'https://app.medplum.com').pathname.split('/')[1];
    if (resourceType in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[resourceType];
      return <Icon />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}

const resourceTypeToDisplayName: Record<string, string> = {
  Patient: 'Patients',
  Appointment: 'Sessions',
  Composition: 'Note Templates',
  Practitioner: 'Practitioner',
  AdminOption: 'Admin Option',
  Dashboard: 'Dashboard',
};

function getDisplayName(name: string): Record<string, string> {
  let displayName = name;
  if (name in resourceTypeToDisplayName) {
    displayName = resourceTypeToDisplayName[name];
  }
  return { displayName };
}