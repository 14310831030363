/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Group, MultiSelect, Select, TextInput, Textarea, Title } from '@mantine/core';
import { Form, useMedplum } from '@medplum/react';
import { templateFormat } from './templateFormat';
import { createTemplate, deleteTemplate, getTemplateDetails, updateTemplate } from '../../utils/util';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultTemplateTypes, defaultPractitionerRoles, defaultTemplateStatuses } from '../../constants';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString } from '@medplum/core';
import { IconPlus, IconMinus } from '@tabler/icons-react';
import { toast } from 'react-toastify';

const AddTemplate = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const id = useParams().id;
  const [formData, setFormData] = useState(templateFormat);
  const [selectedType, setSelectedType] = useState<any>({});
  const template = window.location.href.split('/')[4];

  useEffect(() => {
    if (id) {
      getTemplateDetails(medplum, id)
        .then((response: any) => {
          setFormData(response);
        })
        .catch((error: any) => {
          console.error('Error :', error);
        });
    } else {
      setFormData({ ...templateFormat, section: [] });
    }

  }, [id]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, sectionIndex: number, field: string) => {
    const updatedFormData: any = { ...formData };
    updatedFormData.section[sectionIndex][field] = event.target.value;
    setFormData(updatedFormData);
  };

  const addSection = () => {
    const updatedFormData: any = { ...formData };
    updatedFormData.section.push({ title: '', text: '' });
    setFormData(updatedFormData);
  };

  const removeSection = (sectionIndex: number) => {
    const updatedFormData = { ...formData };
    updatedFormData.section.splice(sectionIndex, 1);
    setFormData(updatedFormData);
  };

  const handleSubmit = () => {
    if (template === 'new') {
      createTemplate(medplum, formData)
        .then((response: any) => {
          console.log('Success');
          toast.success('Template has been created sucessfully.');
          setTimeout(() => {
            window.location.href = '/Composition';
          }, 2000);
        })
        .catch((error: any) => {
          console.error('Error :', error);
        });
    } else {
      updateTemplate(medplum, formData, template)
        .then((response: any) => {
          console.log('Success');
          toast.success('Template has been updated sucessfully.');
          setTimeout(() => {
            window.location.href = '/Composition';
          }, 2000);
        })
        .catch((error: any) => {
          console.error('Error :', error);
        });
    }
  };

  const handleSelectionChange = (selectedValues: any[]) => {
    const updatedFormData: any = { ...formData };
    const selectedValuesObj = selectedValues.map((value) => {
      const role = defaultPractitionerRoles.find((role) => role.code === value);
      return {
        coding: [
          {
            system: role?.system || '',
            code: role?.code || '',
            display: role?.display || '',
          },
        ],
      };
    });
    updatedFormData.category = selectedValuesObj;
    setFormData(updatedFormData);
  };

  const handleTypeChange = (selectedTypeValue: any) => {
    const selectedTypeObj = defaultTemplateTypes.find((type) => type.code === selectedTypeValue);
    setSelectedType(selectedTypeObj);

    const updatedFormData: any = { ...formData };
    updatedFormData.type.coding[0].system = selectedTypeObj?.system;
    updatedFormData.type.coding[0].code = selectedTypeObj?.code;
    updatedFormData.type.coding[0].display = selectedTypeObj?.display;
    setFormData(updatedFormData);
  };

  return (
    <div className="resource-panel">
      <div className="resource-details">
        <Center style={{ textAlign: 'center' }}>
          <Title fs="24px" style={{ lineHeight: '40px' }} fw={700} mb={20}>
            Update Note Template
          </Title>
        </Center>
        <Form onSubmit={handleSubmit}>
          <Center display="block">
            <TextInput
              name="name"
              label="Name"
              placeholder='Enter template name'
              required
              width={100}
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              mb="sm"
            />

            <MultiSelect
              label="Access Roles"
              placeholder="Select access roles for this template"
              data={defaultPractitionerRoles ? defaultPractitionerRoles.map((type) => ({ label: type.display, value: type.code })) : []}
              value={formData.category ? formData.category.map((category: any) => category.coding?.[0]?.code).filter(code => code !== undefined) : []}
              onChange={handleSelectionChange}
              mb="sm"
              required
            />
            <Select
              label="Status"
              placeholder="Select a template status"
              data={defaultTemplateStatuses.map((status) => status.display)}
              onChange={(value) => {
                const selectedStatus = defaultTemplateStatuses.find((status) => status.display === value)?.code;
                setFormData({ ...formData, status: selectedStatus || '' });
              }}
              value={defaultTemplateStatuses.find((status) => status.code === formData.status)?.display}
              mb="sm"
              required
            />
            <Select
              label="Type"
              placeholder="Select a template type"
              data={defaultTemplateTypes.map((type) => ({ label: type.display, value: type.code }))}
              value={selectedType?.code || formData.type.coding[0].code}
              onChange={(value) => handleTypeChange(value)}
              mb="sm"
              required
            />

            <Textarea
              name="systemPrompt"
              label="System Prompt"
              placeholder='Enter system prompt'
              minRows={10}
              mb="sm"
              value={formData.extension[0].valueString}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  extension: [{ ...formData.extension[0], valueString: e.target.value }, formData.extension[1]],
                })
              }
              required
              style={{ resize: 'vertical' }}
            />

            <Textarea
              name="userPrompt"
              label="User Prompt"
              placeholder='Enter user prompt'
              minRows={10}
              mb="sm"
              value={formData.extension[1].valueString}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  extension: [formData.extension[0], { ...formData.extension[1], valueString: e.target.value }],
                })
              }
              required
            />

            <Box my="lg">
              <label htmlFor="section">Template Fields: </label>
              {formData?.section?.map((item: any, index) => (
                <Box key={index} mt="sm">
                  <TextInput label={`Title`} value={item?.title} placeholder='Enter title' mb="sm" required onChange={(e) => handleInputChange(e, index, 'title')} />
                  <TextInput label={`Text`} value={item?.text} placeholder='Enter text' mb="sm" required onChange={(e) => handleInputChange(e, index, 'text')} />
                  <Center
                    style={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      h={25}
                      w={25}
                      p="0"
                      fs="20px"
                      radius={50}
                      className="btn-primary"
                      onClick={() => removeSection(index)}
                    >
                      <IconMinus size={15} />
                    </Button>
                  </Center>
                </Box>
              ))}
            </Box>
            <Center
              style={{
                justifyContent: 'flex-end',
              }}
            >
              <Button h={30} w={30} p="0" radius={50} className="btn-primary" onClick={addSection}>
                <IconPlus size={15} />
              </Button>
            </Center>
          </Center>
          <Group position="right" mt="lg">
            {
              id && (
                <Button
                  className="btn-cancel"
                  px={'lg'}
                  style={{ borderRadius: '25px' }}
                  onClick={() => navigate('/Composition')}
                >
                  Cancel
                </Button>
              )
            }
            {
              id && (
                <Button
                  className="btn-danger"
                  px={'lg'}
                  style={{ borderRadius: '25px' }}
                  onClick={() => {
                    deleteTemplate(medplum, id)
                      .then(() => { console.log('Deleted'); navigate('/Composition'); })
                      .catch((err) => showNotification({ color: 'red', message: normalizeErrorString(err) }));
                  }}
                >
                  Delete
                </Button>
              )
            }
            <Button className="btn-success" px={'lg'} style={{ borderRadius: '25px' }} type="submit">
              Save
            </Button>
          </Group>
        </Form>
      </div>
    </div>
  );
};

export default AddTemplate;
