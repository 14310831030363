import { Button, Center, Title } from '@mantine/core';
import { OperationOutcome, QuestionnaireResponse } from '@medplum/fhirtypes';
import { Document, Loading, Logo } from '@medplum/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionnaireForm } from './QuestionnaireForm';
import axios from '../utils/axios';
import { toast } from 'react-toastify';
import { showNotification } from '@mantine/notifications';
import { getStripeSession, registerOnPayment } from '../utils/util';

export function RegisterForm(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [questionnaire, setQuestionnaire] = useState<any>();
  const [error, setError] = useState<OperationOutcome>();
  const [result, setResult] = useState<QuestionnaireResponse[] | undefined>();
  const [existingEmail, setExistingEmail] = useState<string | undefined>();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get('sessionId');
  const [storedData, setStoredData] = useState<any>(null);
  const storedDataString = sessionStorage.getItem('response_data');
  const storedDataRef = useRef<any>(null);

  const getQuestionnaire = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/pmhs/register/Practitioner-Signup`);
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error(error);
      setLoading(false);
      return null;
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const firstNameParam = urlParams.get('firstName');
  const lastNameParam = urlParams.get('lastName');

  useEffect(() => {
    if (storedDataString) {
      try {
        const parsedData = JSON.parse(storedDataString);
        setStoredData(parsedData);
        storedDataRef.current = parsedData;
      } catch (error) {
        console.error('Error parsing stored data:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (sessionId) {
      getQuestionnaire()
        .then((questionnaireResponse: any) => {
          if (sessionId) {
            const payload = {
              sessionId: sessionId,
            }
            getStripeSession(payload).then(async (data) => {
              setExistingEmail(data.email);
              const registerPayload = {
                email: data?.email,
                firstName: data?.firstName?.length > 0 ? data?.firstName : 'New',
                lastName: data?.lastName?.length > 0 ? data?.lastName : 'User',
                role: data?.role ?? storedDataRef.current?.role ?? 'Prescriber',
                npiNumber: data?.npiNumber ?? ''
              }

              await registerOnPayment(registerPayload).then((response) => {
                showNotification({ color: 'green', message: 'Your account is almost ready. Finish setting up your account to start your journey!' });
                questionnaireResponse?.item?.forEach((questionnaire: any) => {
                  questionnaire.item.forEach((item: any) => {
                    if (item.linkId === 'Q22') {
                      item.answer = [{
                        valueString: data.email
                      }]
                    }
                    else if (item.linkId === 'Q1' && (firstNameParam || storedData?.basic?.firstName)) {
                      const firstNameFromStoredData = storedData?.basic?.firstName;
                      item.answer = [{
                        valueString: firstNameParam || firstNameFromStoredData
                      }]
                    }
                    else if (item.linkId === 'Q2' && (lastNameParam || storedData?.basic?.lastName)) {
                      const lastNameFromStoredData = storedData?.basic?.lastName;
                      item.answer = [{
                        valueString: lastNameParam || lastNameFromStoredData
                      }]
                    }
                  });
                });
                setQuestionnaire(questionnaireResponse);
              }).catch((error: any) => {
                if (error.response) {
                  const { status } = error.response;
                  if (status === 404 || status === 409) {
                    const errorMessage = status === 404
                      ? 'User account not found, please try again'
                      : 'An account with this email already exists. Please log in or use the "Forgot Password" option to reset your credentials.';

                    navigate('/signin');
                    setTimeout(() => {
                      toast.error(errorMessage);
                    }, 1000);
                    return;
                  }
                }
                //continue with the questionnaire in case of any falure in registration on payment
                questionnaireResponse?.item?.forEach((questionnaire: any) => {
                  questionnaire.item.forEach((item: any) => {
                    if (item.linkId === 'Q22') {
                      item.answer = [{
                        valueString: data.email
                      }]
                    }
                    else if (item.linkId === 'Q1' && (firstNameParam || storedData?.basic?.firstName)) {
                      const firstNameFromStoredData = storedData?.basic?.firstName;
                      item.answer = [{
                        valueString: firstNameParam || firstNameFromStoredData
                      }]
                    }
                    else if (item.linkId === 'Q2' && (lastNameParam || storedData?.basic?.lastName)) {
                      const lastNameFromStoredData = storedData?.basic?.lastName;
                      item.answer = [{
                        valueString: lastNameParam || lastNameFromStoredData
                      }]
                    }
                  });
                });
                setQuestionnaire(questionnaireResponse);
              });
            }).catch((error) => {
              console.log(error)
            });
          } else {
            setQuestionnaire(questionnaireResponse);
          }
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
          setError(error);
          setLoading(false);
        });
    } else {
      window.location.href = '/signin';
    }
  }, []);

  if (error) {
    return (
      <Document>
        <pre data-testid="error">{JSON.stringify(error, undefined, 2)}</pre>
      </Document>
    );
  }

  if (result) {
    return (
      <Center className="resource-tab-panel questionnaire" style={{ position: 'absolute', top: '130px' }}>
        <Center
          p="30px"
          className="resource-details"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Center my="30px" w="100%">
            <Logo size={150} />
          </Center>

          <Title className="quiz-header">Registration completed successfully.</Title>

          <Button onClick={() => navigate('/signin')} className="bg-main">
            Continue to Login
          </Button>
        </Center>
      </Center>
    );
  }

  if (loading || !questionnaire) {
    return <Loading />;
  }

  return (
    <Center className="resource-tab-panel questionnaire" style={{ flexDirection: 'column' }}>
      <Center my="30px" w="100%" className="signin-logo">
        <Logo size={150} />
      </Center>

      <div className="resource-details">
        {questionnaire && <QuestionnaireForm questionnaire={questionnaire} onSubmit={handleSubmit} />}
      </div>
    </Center>
  );

  async function handleSubmit(questionnaireResponse: QuestionnaireResponse): Promise<void> {
    const responses = [] as QuestionnaireResponse[];
    let foundPostalCode = '';
    for (let i = 0; i < (storedData?.addresses?.length || 0); i++) {
      if (storedData.addresses[i]?.teleNumber) {
        foundPostalCode = storedData.addresses[i].teleNumber || storedData.addresses[i].telephone_number;
        break;
      }
    }
    if (existingEmail) {
      questionnaireResponse?.item?.forEach((questionnaire: any) => {
        questionnaire.item.forEach((item: any) => {
          if (item.linkId === 'Q22') {
            item.answer = [{
              valueString: existingEmail,
            }]
          }
          else if (item.linkId === 'Q1' && item.answer.length === 0) {
            const firstNameFromStoredData = storedData?.basic?.firstName || storedData?.basic?.aoFirstName || storedData?.basic?.first_name || storedData?.basic?.authorized_official_first_name;
            item.answer = [{
              valueString: firstNameParam || firstNameFromStoredData
            }];
          }
          else if (item.linkId === 'Q2' && item.answer.length === 0) {
            const lastNameFromStoredData = storedData?.basic?.lastName || storedData?.basic?.aoLastName || storedData?.basic?.last_name || storedData?.basic?.authorized_official_last_name;
            item.answer = [{
              valueString: lastNameParam || lastNameFromStoredData
            }]
          }
          else if (item.linkId === 'Q20' && item.answer.length === 0) {
            const personalEmail = existingEmail;
            item.answer = [{
              valueString: personalEmail
            }]
          }
          else if (item.linkId === 'Q15' && (storedData?.number)) {
            const npiFromStoredData = storedData?.number;
            item.answer = [{
              valueString: npiFromStoredData
            }]
          }
          else if (item.linkId === 'Q7' && item.answer.length === 0) {
            const streetAddress = storedData?.addresses?.[0]?.addressLine1 || storedData?.addresses?.[0]?.address_1;
            if (streetAddress) {
              item.answer = [{
                valueString: streetAddress
              }];
            }
          }
          else if (item.linkId === 'Q9' && item.answer.length === 0) {
            const city = storedData?.addresses?.[0]?.city;
            if (city) {
              item.answer = [{
                valueString: city
              }];
            }
          }
          else if (item.linkId === 'Q10' && item.answer.length === 0) {
            const state = storedData?.addresses?.[0]?.state;
            if (state) {
              item.answer = [{
                valueString: state
              }];
            }
          }
          else if (item.linkId === 'Q11' && item.answer.length === 0) {
            const postalCode = storedData?.addresses?.[0]?.postalCode || storedData?.addresses?.[0]?.postal_code;
            if (postalCode) {
              item.answer = [{
                valueString: postalCode
              }];
            }
          }
          else if (item.linkId === 'Q12' && item.answer.length === 0) {
            const practicePhone = storedData?.practiceLocations?.[0]?.teleNumber;
            if (practicePhone) {
              item.answer = [{
                valueString: practicePhone
              }]
            }
          }
          else if (item.linkId === 'Q19' && item.answer.length === 0) {
            const personalCell = foundPostalCode || storedData?.practiceLocations?.[0]?.teleNumber;
            if (personalCell) {
              item.answer = [{
                valueString: personalCell
              }]
            }
          }
          else if (item.linkId === 'Q6' && item.answer.length === 0) {
            const practiceName = storedData?.basic?.name || storedData?.basic?.first_name;
            if (practiceName) {
              item.answer = [{
                valueString: practiceName
              }]
            }
          }
        });
      });
    }

    const username = questionnaireResponse?.item?.find((section: any) => section?.linkId === 'g33')?.item?.find(item => item?.linkId === 'Q22')?.answer?.[0]?.valueString;
    const password = questionnaireResponse?.item?.find((section: any) => section?.linkId === 'g33')?.item?.find(item => item?.linkId === 'Q23')?.answer?.[0]?.valueString;
    try {
      const payload = {
        questionnaireResponse: questionnaireResponse,
        role: storedData?.role
      }
      const response = await axios.post(`/pmhs/register`, payload);
      if (response) {
        showNotification({ color: 'green', message: 'Registration completed successfully.' });
        responses.push(response?.data);
        setResult(responses);
        navigate('/signin', { state: { username: username, password: password } });
      }
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        toast.error('An account with this email already exists. Please log in or use the "Forgot Password" option to reset your credentials.');
      } else {
        toast.error('An error occurred during registration.');
      }
    }
  }
}
