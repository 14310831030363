import React, { useEffect, useRef } from 'react';
import { useEditor } from '@tiptap/react';
import { RichTextEditor } from '@mantine/tiptap';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Highlight from '@tiptap/extension-highlight';
import { Button, Group, Tooltip, Loader, Text } from '@mantine/core';
import { IconCopy, IconDownload, IconMail } from '@tabler/icons-react';

export const useRichTextEditor = (content: string, setContent: React.Dispatch<React.SetStateAction<string>>, setIsSummaryUpdated: React.Dispatch<React.SetStateAction<boolean>>) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            Subscript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content,
        onUpdate: ({ editor }) => {
            const updatedContent = editor.getHTML();
            setContent(updatedContent);
            setIsSummaryUpdated(true);
        },
    });


    const isInitialContentSet = useRef(false);

    useEffect(() => {
        if (editor && !editor.isDestroyed) {
            if (!isInitialContentSet.current) {
                editor.commands.setContent(content, false);
                isInitialContentSet.current = true;
            }
        }
    }, [editor]);

    useEffect(() => {
        if (editor && isInitialContentSet.current && content !== editor.getHTML()) {
            editor.commands.setContent(content);
        }
    }, [content, editor]);

    return editor;
};

const RichTextEditorContainer = ({
    label,
    value,
    editor,
    isCheckActionBtn,
    section,
    handleCopy,
    handleDownload,
    copiedSection,
    isCopyLoading,
    downloadingSection,
    isDownloadLoading,
    handleEmailClick,
}: {
    label: string;
    value: string;
    editor: any;
    isCheckActionBtn: boolean;
    section: string;
    handleCopy: (value: string, section: string) => void;
    handleDownload: (label: string, section: string) => void;
    copiedSection: string | null;
    isCopyLoading: boolean;
    downloadingSection: string | null;
    isDownloadLoading: boolean;
    handleEmailClick: (label: string) => void;
}) => {
    return (
        <div className="richtext-editor-container">
            <Text m={10} fw={500}>{label}</Text>
            <RichTextEditor editor={editor}>
                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Underline />
                        <RichTextEditor.Strikethrough />
                        <RichTextEditor.ClearFormatting />
                        <RichTextEditor.Highlight />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Hr />
                        <RichTextEditor.Link />
                        <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                        <RichTextEditor.AlignLeft />
                        <RichTextEditor.AlignCenter />
                        <RichTextEditor.AlignJustify />
                        <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content />
            </RichTextEditor>
            {isCheckActionBtn && (
                <Group mt="sm" className='action-buttons'>
                    <Tooltip label="Copy" withArrow>
                        <Button
                            size="xs"
                            className={`btn-primary ${copiedSection === section && 'btn-success'}`}
                            disabled={!value || isCopyLoading}
                            onClick={() => handleCopy(value, section)}
                        >
                            {isCopyLoading ? (
                                <Loader size="xs" />
                            ) : copiedSection === section ? (
                                'Copied'
                            ) : (
                                <IconCopy size={18} />
                            )}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Download" withArrow>
                        <Button
                            size="xs"
                            className={`btn-primary ${downloadingSection === section && 'btn-success'}`}
                            disabled={!value || isDownloadLoading}
                            onClick={() => handleDownload(label, section)}
                        >
                            {downloadingSection === section ? 'Downloading...' : <IconDownload size={18} />}
                        </Button>
                    </Tooltip>
                    <Tooltip label="Email" withArrow>
                        <Button
                            size="xs"
                            className="btn-primary"
                            disabled={!value}
                            onClick={() => handleEmailClick(label)}
                        >
                            <IconMail size={18} />
                        </Button>
                    </Tooltip>
                </Group>
            )}
        </div>
    );
};

export default RichTextEditorContainer;